import './Refsheet.css';
import refsheet from './Keea_ref.png';

function Refsheet() {
  return (
    <div className="Refsheet">
      <h1>Ref Sheet</h1>
      <img src={refsheet} alt="Reference sheet for Keea"></img>
    </div>
  );
}

export default Refsheet;
