import React, { useEffect, useState } from 'react';

import reily_icon from './icons/reily_floating_icon_wm.png';
import thay_icon from './icons/thay_acrylic_badge_icon.png';
import hypno_icon from './icons/hypno_icon.jpg';
import badge_icon from './icons/icon_badge.png';

import './Header.css';

function randIdx(arr) {
    return Math.floor(Math.random() * arr.length);
}

const slogans = [
    "Wow! A dog on the internet!",
    "I completed Super Mario Sunshine once!",
    "Look at that curl, baby!",
    "Am I the good dog??? Is it me????",
    "WOOF WOOF ARF BARK AWOOOO!!!",
    "Can you license a dog under MIT?",
    "Stream MONTERO by LIL NAS X!",
    "wag wag wag wag wag wag",
    "Arch user btw.",
    "Escaped from Ohio!",
    "Now available in 24bit/96khz!",
    "I stole this gimmick from Minecraft!",
    "Certified Good Girl(tm)!",
    "Check my TLS config!",
    "I forgor 💀",
    "Kweh?",
]

function Header() {
    const [index, setIndex] = useState(randIdx(slogans));

    useEffect(() => {
        const timer = setInterval(() => {
            let newIndex = index;
            while (newIndex === index) {
                newIndex = randIdx(slogans);
            }
            setIndex(newIndex);
        }, 4000);
        return () => clearInterval(timer);
    }, [index]);

    return (
        <header className="Header">
            <Icon />
            <div className="title">
                <h1>keea.dog</h1>
                <div className="subtitle">{slogans[index]}</div>
            </div>
        </header>
    );
}

const icons = [
    reily_icon,
    thay_icon,
    hypno_icon,
    badge_icon,
]

function Icon() {
    const [index, setIndex] = useState(randIdx(icons));

    useEffect(() => {
        const timer = setInterval(() => {
            let newIndex = index;
            while (newIndex === index) {
                newIndex = randIdx(icons);
            }
            setIndex(newIndex);
        }, 4000);
        return () => clearInterval(timer);
    }, [index]);

    const style = {
        backgroundImage: `url(${icons[index]})`,
    }
    console.log(index);
    return (
        <div><div className="icon" style={style}></div></div>
    );
}

export default Header;
